(function () {
    const linkedInFeedEl = document.getElementById('linkedin-feed');

    var linkedInFeedSlider = new Swiper(linkedInFeedEl, {
        speed: 750,
        spaceBetween: 40,
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        breakpoints: {
            1024: {
                slidesPerView: 3
            },
            640: {
                slidesPerView: 2
            },
            360: {
                slidesPerView: 1
            }
        }
    });
}());